import React, { useState } from 'react'

import { CircularProgress, Snackbar } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import { useRouter } from 'next/router'

import { Alert } from '~/components/Alert'
import { NoAuthLayout } from '~/components/AppLayout/NoAuthLayout'
import Copyright from '~/components/Copyright'
import { setUserCookies } from '~/helpers/CookiesHelper'
import { loginUser } from '~/services/auth'
import { getUserDataWithoutToken } from '~/services/user'
import {
  AUTH_ACTIONS,
  useAuthDispatch,
  useAuthState,
} from '~/store/AuthContext/reducer'
import { useUserDispatch, USER_ACTIONS } from '~/store/UserContext/reducer'

import { FitnessIcon } from './dashboard/icons/fitness'
import { useStyles } from './styles'

function SignIn() {
  const classes = useStyles()
  const authState = useAuthState()
  const authDispatch = useAuthDispatch()
  const userDispatch = useUserDispatch()

  const router = useRouter()
  const { redirect } = router.query

  const [alert, setAlert] = useState({
    isOpen: false,
    message: '',
    severity: '',
  })

  const [loginData, setLoginData] = useState({ email: '', password: '' })

  const handleCloseAlert = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }

    setAlert(prev => ({
      ...prev,
      isOpen: false,
      message: '',
      severity: '',
    }))
  }

  const handleChange = e => {
    setLoginData({
      ...loginData,
      [e.target.name]: e.target.value,
    })
  }

  const handleSubmit = async e => {
    e.preventDefault()

    try {
      authDispatch({
        type: AUTH_ACTIONS.FETCH_AUTH,
      })

      const { jwt } = await loginUser({
        identifier: loginData.email,
        password: loginData.password,
      })

      const userData = await getUserDataWithoutToken({
        jwt,
      })

      setUserCookies({ token: jwt })

      authDispatch({
        type: AUTH_ACTIONS.SET_TOKEN,
        payload: jwt,
      })

      const userPayload = {
        id: userData._id,
        email: userData.email,
        weight: userData.weight,
        role: userData.role?.name,
        username: userData.username,
        adminReferrer: userData.admin_referrer ?? null,
      }

      userDispatch({
        type: USER_ACTIONS.SET_USER_DATA,
        payload: userPayload,
      })

      authDispatch({
        type: AUTH_ACTIONS.SET_AUTH_DATA,
      })

      setAlert(prev => ({
        ...prev,
        isOpen: true,
        message: 'Login efetuado com sucesso!',
        severity: 'success',
      }))

      return router.push(redirect ?? `/dashboard/${userPayload.id}`)
    } catch (err) {
      authDispatch({
        type: AUTH_ACTIONS.FETCH_AUTH_ERROR,
      })

      setAlert(prev => ({
        ...prev,
        isOpen: true,
        message:
          'Não foi possível realizar inciar a sessão, por favor, verifique seus dados e tente novamente.',
        severity: 'error',
      }))
    }
  }

  return (
    <>
      <div className={classes.paper}>
        <div style={{ width: '100%', maxWidth: '440px' }}>
          <FitnessIcon />
        </div>
        <h1
          style={{
            color: '#001f2e',
            fontWeight: 900,
            margin: '18px 0 0',
          }}
        >
          Bem-vindo(a)!
        </h1>
        <Snackbar
          open={alert.isOpen}
          autoHideDuration={6000}
          onClose={handleCloseAlert}
        >
          <Alert onClose={handleCloseAlert} severity={alert.severity}>
            {alert.message}
          </Alert>
        </Snackbar>
        <form className={classes.form} noValidate onSubmit={handleSubmit}>
          <TextField
            value={loginData.email}
            onChange={handleChange}
            variant='outlined'
            margin='normal'
            required
            fullWidth
            id='email'
            label='Email'
            name='email'
            autoFocus
          />
          <TextField
            value={loginData.password}
            onChange={handleChange}
            variant='outlined'
            margin='normal'
            required
            fullWidth
            name='password'
            label='Senha'
            type='password'
            id='password'
          />
          <Button
            type='submit'
            fullWidth
            variant='contained'
            color='primary'
            className={classes.submit}
          >
            {authState.loading ? (
              <CircularProgress size={30} color='inherit' />
            ) : (
              'Entrar'
            )}
          </Button>
        </form>
      </div>
      <Box mt={3}>
        <Copyright />
      </Box>
    </>
  )
}

SignIn.layout = NoAuthLayout

export default SignIn
