import { apiWithoutToken } from '~/services/_config'

export const loginUser = async ({ identifier, password }) => {
  try {
    const { data } = await apiWithoutToken.post('/auth/local', {
      identifier,
      password,
    })

    return data
  } catch (error) {
    throw new Error('COULD_NOT_LOGIN_USER', error)
  }
}
