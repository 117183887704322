import React from 'react'

import { Container } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100vh',
  },
}))

export const NoAuthLayout = ({ children }) => {
  const classes = useStyles()

  return (
    <Container component='main' className={classes.container}>
      {children}
    </Container>
  )
}
